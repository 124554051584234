import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAccount, getIsAuthenticated } from '../../reducers/authReducer';
import HelpCenterWidget from './src/help-center-container';
import logo from './src/wa-team.png';

const HelpCenter = ({ isAuthenticated, account }) => {
    const isTrial = account?.billingPlan === 'Trial';

    if (isAuthenticated) {
        return ( <HelpCenterWidget domNodeId="help-center" logo={logo} isTrial={isTrial} serviceNotice={false} />);
    } else {
        return null;
    }
};

HelpCenter.propTypes = {
    isAuthenticated: propTypes.bool.isRequired,
    // eslint-disable-next-line react/require-default-props
    path: propTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
    email: propTypes.string,
    // eslint-disable-next-line react/require-default-props
    accountId: propTypes.string,
};

function mapStateToProps(state) {
    return {
        isAuthenticated: getIsAuthenticated(state),
        account: getAccount(state),
    };
}

export default connect(
    mapStateToProps,
)(HelpCenter);
