import { useEffect } from 'react';
import { zendesk } from 'wa.admin-ui.in-product-scripts';

const featureToggleService =
{
    // Feature toggle service not avaialbe in scope of TNS, so assume features are enabled
    accessGranted: () => true,
};

const useInitZendeskWidget = (data) => {
    useEffect(() => {
        if (data) {
            zendesk(data, null, featureToggleService, true);
        }
    }, [data]);
};

export default useInitZendeskWidget;
