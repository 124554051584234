const setChatConfiguration =
  (widgetHandler, startChat, endChat, isUnread, clearUnread, setChatOnline, setChatOffline) => {
      if (typeof window.zE !== 'undefined') {
          setChatOnline();

          window.zE('messenger:on', 'open', () => {
              startChat();
              widgetHandler();
          });

          window.zE('messenger:on', 'close', () => {
              clearUnread();
              widgetHandler();
          });

          window.zE('messenger:on', 'unreadMessages', (count) => {
              isUnread(count);
          });
      } else {
          setTimeout(
              () =>
                  setChatConfiguration(
                      widgetHandler,
                      startChat,
                      endChat,
                      isUnread,
                      clearUnread,
                      setChatOnline,
                      setChatOffline), 200);
      }
  };

export default setChatConfiguration;