export const showLiveChatWindow = (onShow) => {
    if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'show');
        if (typeof onShow === 'function') {
            onShow();
        }
    } else {
        return null;
    }
};

export const hideLiveChatWindow = (onHide) => {
    if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'hide');
        if (typeof onHide === 'function') {
            onHide();
        }
    } else {
        return null;
    }
};

export const openLiveChatWindow = (onOpen) => {
    if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'open');
        if (typeof onOpen === 'function') {
            onOpen();
        }
    } else {
        return null;
    }
};

export const closeLiveChatWindow = (onClose) => {
    if (typeof window.zE !== 'undefined') {
        window.zE('messenger', 'close');
        if (typeof onOpen === 'function') {
            onClose();
        }
    } else {
        return null;
    }
};