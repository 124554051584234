import React from 'react';
import { useL10nContext } from 'wa.admin-ui.l10n';
import Button from '../../../../../../../../../Common/button';
import { faCommentDots, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { useSectionContext }
    from '../../../../../../../entities/help-widget/context/section-context-provider';
import { useChatContext } from '../../../../../../../entities/help-widget/context/chat-context-provider';

import sectionKeys from '../../../../../../../constants/section-keys';
import { openLiveChatWindow } from '../../../../../../../utils/zd-messaging/api';

const ContactUs = () => {
    const { labels } = useL10nContext();
    const { setSection } = useSectionContext();
    const { online } = useChatContext();

    return (
        <>
            <h6>
                {labels.PAID.CONTACT_SECTION_TITLE}
            </h6>
            <p>
                {labels.PAID.CONTACT_SECTION_DESCRIPTION}
            </p>
            <div className="d-flex pb-2">
                <Button
                    icon={faEnvelope}
                    className="mr-4 px-4"
                    onClick={() => setSection(sectionKeys.SECTION_EMAIL)}
                >
                    {labels.EMAIL_BUTTON_LABEL}
                </Button>
                <Button disabled={!online} className="px-4" icon={faCommentDots} onClick={online && openLiveChatWindow}>
                    {online ? labels.CHAT_BUTTON_LABEL : 'Chat offline'}
                </Button>
            </div>
            <span className="text-muted small">
                {labels.PAID.SUPPORT_HOURS_NOTICE}
            </span>
        </>
    );
};

export default ContactUs;
