import actionTypes from '../constants/actionTypes';
import initialState from './initialState';

// eslint-disable-next-line default-param-last,@typescript-eslint/default-param-last
export default function authReducer(state = initialState.auth, action) {
    switch (action.type) {
        case actionTypes.Auth.SIGN_IN.start:
            return {
                ...state,
                authStatus: 'auth-in-progress',
                authFailed: false,
            };

        case actionTypes.Auth.SIGN_IN.success:
            return {
                ...state,
                authStatus: 'authenticated',
                accessToken: action.accessToken,
                accountId: action.accountId,
            };

        case actionTypes.Auth.SIGN_IN.failure:
            return {
                ...state,
                authStatus: 'loggedOut',
                accessToken: null,
                email: null,
                authFailed: true,
                accountId: null,
            };

        case actionTypes.Auth.SIGN_IN_AMBIGOUS_ACCOUNT:
            return {
                ...state,
                authStatus: 'auth-in-progress-ambigous',
                accessToken: action.accessToken,
                ambiguousAccount: action.ambiguousAccount,
                availableAccounts: action.availableAccounts,
            };

        case actionTypes.Auth.SIGN_OUT:
            return {
                ...state,
                authStatus: 'loggedOut',
                accessToken: null,
                email: null,
                authFailed: false,
                accountId: null,
            };

        case actionTypes.Auth.REQUEST_RESTORE_PASSWORD.start:
            return { ...state, requestingPasswordRestore: true };

        case actionTypes.Auth.REQUEST_RESTORE_PASSWORD.failure:
            return {
                ...state,
                requestingPasswordRestore: false,
                passwordRestoreError: action.error,
                passwordRestoreRequested: false,
            };

        case actionTypes.Auth.REQUEST_RESTORE_PASSWORD.success:
            return {
                ...state,
                requestingPasswordRestore: false,
                passwordRestoreError: null,
                passwordRestoreRequested: true,
            };

        case actionTypes.Auth.RESET_PASSWORD.start:
            return { ...state, resetingPassword: true };

        case actionTypes.Auth.RESET_PASSWORD.failure:
            return {
                ...state,
                resetingPassword: false,
                resetPasswordError: action.error,
                passwordResetSucceed: false,
            };

        case actionTypes.Auth.RESET_PASSWORD.success:
            return {
                ...state,
                resetingPassword: false,
                resetPasswordError: null,
                passwordResetSucceed: true,
            };

        case actionTypes.Auth.FETCH_CURRENT_USER_INFO.start:
            return { ...state, fetchingCurrentUser: true };

        case actionTypes.Auth.FETCH_CURRENT_USER_INFO.failure:
            return {
                ...state,
                fetchingCurrentUser: false,
                currentUserError: action.error,
                me: null,
            };

        case actionTypes.Auth.FETCH_CURRENT_USER_INFO.success:
            return {
                ...state,
                fetchingCurrentUser: false,
                currentUserError: action.error,
                me: {
                    user: action.user,
                    account: action.account,
                },
            };
        case actionTypes.Auth.SETUP_COMPLETE.start:
            return { ...state, savingSetupCompletion: true };

        case actionTypes.Auth.SETUP_COMPLETE.failure:
            return {
                ...state,
                savingSetupCompletion: false,
                currentUserError: action.error,
                me: null,
            };

        case actionTypes.Auth.SETUP_COMPLETE.success:
            return {
                ...state,
                savingSetupCompletion: false,
                currentUserError: action.error,
                me: {
                    user: { ...state.me.user },
                    account: { ...state.me.account, setupComplete: true },
                },
            };

        default:
            return state;
    }
}

// selectors

export const getMe = (state) => state.auth.me;

export const getIsBillingPlanProhibited = (state) => {
    const { account } = getMe(state) || {};
    const { billingPlan } = account || {};
    const normalizedPlan = (billingPlan || '').toLowerCase();

    return !normalizedPlan || normalizedPlan === 'trial' || normalizedPlan === 'free';
};

export const getUser = (state) => {
    const { user = null } = getMe(state) || {};

    return user;
};

export const getAccount = (state) => {
    const { account = null } = getMe(state) || {};

    return account;
};

export const getIsAuthenticated = (state) => state.auth.authStatus === 'authenticated';

export const getAccessToken = (state) => state.auth.accessToken;

export const getHasTwilioAccount = (state) => {
    const { hasTwilioAccount } = getAccount(state) || {};

    return !!hasTwilioAccount;
};

export const getTwillioSubscriptionFromUser = (state) => {
    const { twilioSubscription } = getAccount(state) || {};

    return twilioSubscription;
};

export const getSetupComplete = (state) => {
    const { setupComplete } = getAccount(state) || {};
    return !!setupComplete;
};

export const getSetupCompleteSaving = (state) => {
    const { auth } = state;
    return !!auth.savingSetupCompletion;
};
